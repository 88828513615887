const dropDown = () => {
    const dropDowns = document.querySelectorAll('[data-dropdown]');
    const dropTexts = document.querySelectorAll('.howThisWork-block__wrapperText');
    const dropDownImgs = document.querySelectorAll('.howThisWork-block__img');

    dropDowns.forEach(dropDownItem => {
        dropDownItem.addEventListener('click', () => {
            console.log(dropDownItem);

            // Показываем нужный текст и меняем иконку на минус
            dropTexts.forEach(textElement => {
                const dataDropdown = parseInt(dropDownItem.getAttribute('data-dropdown'));
                const dataText = parseInt(textElement.getAttribute('data-text'));

                if (dataDropdown === dataText) {
                    textElement.classList.toggle('none');
                }

                if (dataDropdown === dataText && textElement.classList.contains('none')) {
                    dropDownItem.querySelector('.howThisWork-block__img').src = "img/plus.png";
                } else if (dataDropdown === dataText && !textElement.classList.contains('none')) {
                    dropDownItem.querySelector('.howThisWork-block__img').src = "img/minus.png";
                }
            });
        });
    });

    const dropdownBtn = document.querySelector('.header-dropdown__dropbtn');
    const dropdownContent = document.querySelector('.dropdown-content');

    dropdownBtn.addEventListener('click', () => {
        dropdownContent.classList.toggle('none')
    })
}

export default dropDown;
