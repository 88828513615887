function tab() {
    const tabs = document.querySelectorAll('.tabs-catalog__li');
    const tabsContent = document.querySelectorAll('.tabs-content__item');
    const styleTab = document.querySelector('#styleTab');

    styleTab.style.cssText = 'background: rgb(0 134 255); transition: 0.5s all; z-index: 999';

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            // Сначала скрываем все контентные блоки и сбрасываем стили табов
            tabsContent.forEach(content => {
                content.classList.remove('tabs-content__item-active');
            });

            tabs.forEach(tab => {
                tab.style.cssText = 'background: #fff; transition: 0.5s all';
            });

            // Показать контентный блок с атрибутом data-tab, соответствующим активному табу
            tabsContent.forEach(content => {
                if (tab.getAttribute('data-tab') === content.getAttribute('data-tab')) {
                    console.log(`Тут есть атрибут с значением ${tab.getAttribute('data-tab')}`);
                    content.classList.add('tabs-content__item-active');
                }
            });

            // Применение стилей к активному табу
            tab.style.cssText = 'background: rgb(0 134 255); transition: 0.5s all; z-index: 999';
        });
    });
}

export default tab;