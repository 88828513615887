import $ from 'jquery';
import 'slick-carousel';

$('.tabs-catalog').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 2000,
    arrows: true,
    responsive: [
        {
            breakpoint: 1439,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                arrows: false,
                centerMode: true,
                centerPadding: '0px',
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 991,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 2,
            },
        },
        {
            breakpoint: 575,
            settings: {
                arrows: false,
                centerMode: true,
                slidesToShow: 1,
            },
        },
    ],
});