function cards () {
    // Динамическое добавление елементов на страницу
    class MenuCard {
        constructor (src, title, url, ...clases) {
            this.src = src;
            this.title = title;
            this.url = url;
            this.clases = clases;
        }

        render () {
            const blockCard = document.querySelector('.services-block');
            const element = document.createElement('div');
            blockCard.appendChild(element);

            if (this.clases === 0) {
                this.element = 'services-block__card';
                element.classList.add(this.element);
            } else {
                this.clases.forEach(className => element.classList.add(className));
            }

            element.innerHTML = `
                            <img src="${this.src}" class="services-block__img">
                            <h1 class="services-block__title">${this.title}</h1>
                            <a class="a_teg" href=${this.url} target="_blank">
                                <button data-services class="services-block__btn">Открыть проект</button>
                            </a>
                    </div>`;
        }
    }

    new MenuCard('img/one_floor.png', '1-к квартира', 'https://singleapart.3dtourdnepr.pp.ua/', 'services-block__card').render();

new MenuCard('img/two_floor.png', '2-х этажная квартира', 'https://secondapart.3dtourdnepr.pp.ua/', 'services-block__card').render();

    new MenuCard('img/bunker.png', 'Убежище', 'https://college.3dtourdnepr.pp.ua/', 'services-block__card').render();
}

export default cards;