import './slider';
import arrow from './modules/arrow';
import cards from './modules/cards';
import checkNumInput from './modules/checkNumInput';
import form from './modules/form';
import modal from './modules/modal';
import tab from './modules/tabs';
import burger from './modules/burger';
import dropDown from './modules/dropdown';

arrow();
cards();
checkNumInput('[data-number]');
form();
modal();
tab();
burger();
dropDown();

