const checkNumInput = (selector) => {
    const inputs = document.querySelectorAll(selector);

    inputs.forEach(input => {
        input.addEventListener('input', () => {
            if (input.hasAttribute('data-number')) {
                // Получаем значение input
                input.value = input.value.replace(/\D/, '');
            }
        });
    });

    // ! Эта функция выполняет запрет написания любых букв или символов с помощью регулярного выражения, поэтому при написании подобного в инпут, который у нас является аргументом функции, все значения кроме цифр будет стираться, так же можем переиспользовать эту функцию там где нам нужно
}

export default checkNumInput;