// Модальное окно
function modal () {
    const modal = document.querySelector('.modal');
    const modalClose = document.querySelector('.modal__close');
    const buttons = document.querySelectorAll('[data-call]');

    buttons.forEach(btn => {
        btn.addEventListener('click', () => {
            openModal();
        })
    })

    modalClose.addEventListener('click', () => {
        closeModal();
    })

    function closeModal() {
        document.body.style.overflow = 'visible';
        modal.classList.add('hide');
        modal.classList.remove('show');
        setTimeout(() => {
            modal.style.display = 'none';
        }, 500); // Задержка для завершения анимации
    }

    function openModal() {
        document.body.style.overflow = 'hidden';
        setTimeout(() => {
            modal.style.display = 'block';
            modal.classList.add('show');
            modal.classList.remove('hide');
        }, 10); // Небольшая задержка для корректного запуска анимации
    }

    document.addEventListener('keydown', (e) => {
        if (e.code === 'Escape' && modal.style.display === 'block') {
            closeModal();
        }
    })

    modal.addEventListener('click', (e) => {
        if (e.target === modal || e.target.getAttribute('data-close') == '') {
            closeModal();
        }
    })
}

export default modal;