function burger () {
    const hamburger = document.querySelector('.hamburger');
    const menu = document.querySelector('.header-block__ul')

    hamburger.addEventListener('click', () => {
        menu.classList.toggle('active');
        hamburger.classList.toggle('animation');
        menu.classList.remove('hidden');
        
        if (menu.classList.contains('active')) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    })

    menu.addEventListener('click', (e) => {
        console.log(e.target)
        if (e.target.classList.contains('header-block__link')) {
            menu.classList.remove('active');
            document.body.style.overflow = '';
            hamburger.classList.remove('animation')
            /* menu.classList.add('hidden'); */
        }
    })
}

export default burger;