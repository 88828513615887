function form() {
    // Отправка данных через fetch
    const forms = document.querySelectorAll('form');

    const message = {
        loading: 'img/spinner.gif',
        success: 'Заявка успешно отправлена',
        failed: 'Что-то пошло не так',
    };

    forms.forEach((item) => {
        postData(item);
    });

    function postData(form) {
        form.addEventListener('submit', (e) => {
            e.preventDefault();

            // Для того чтобы спиннер не показывался повторно
            const spinner = form.parentElement.querySelector('.spinner');
            if (spinner) {
                spinner.remove();
            }

            // Создали загрузку-спиннер, добавили ей стили и поместили в форму
            const statusMessage = document.createElement('img');
            statusMessage.src = message.loading;
            statusMessage.style.cssText =
                'display: block; margin: 15px auto; max-width: 10%';
            statusMessage.classList.add('spinner');
            form.insertAdjacentElement('afterend', statusMessage);

            const formData = new FormData(form); // Создали обьект в котором будем формироваться ответ отправки
            console.log(formData);

            const object = {};
            formData.forEach(function (value, key) {
                object[key] = value;
            });
            console.log(object);

            fetch('server.php', {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(object),
            })
                .then((data) => data.text())
                .then((data) => {
                    console.log(data);
                    form.reset();
                    setTimeout(() => {
                        const success = document.createElement('div');
                        success.innerHTML = message.success;
                        success.classList.add('success');

                        const existingSuccess =
                            form.parentElement.querySelector('.success');
                        if (existingSuccess) {
                            existingSuccess.remove();
                        }

                        success.style.cssText =
                            'font-size: 16px; color: #000; font-family: Nunito Sans; text-transform: uppercase; text-align: center; font-weight: 600; padding-top: 15px';
                        form.insertAdjacentElement('afterend', success);
                        setTimeout(() => {
                            success.remove();
                        }, 3000);
                    }, 3000);
                })
                .catch((error) => {
                    setTimeout(() => {
                        const failed =
                            form.parentElement.querySelector('.failed');
                        if (failed) {
                            failed.remove();
                        }

                        const messageFailed = message.failed;
                        messageFailed.classList.add('failed');
                        form.insertAdjacentElement('afterend', messageFailed);
                    }, 3000);
                    console.error('Ошибка при отправке данных:', error);
                })
                .finally(() => {
                    setTimeout(() => {
                        statusMessage.remove();
                    }, 3000); // Задержка в 5000 миллисекунд (5 секунд)
                });
        });
    }
}

export default form;
